import { Team } from './team'

export const teams: Team[] = [
	{
		id: 1,
		name: 'New Jersey Devils',
		link: '/api/v1/teams/1',
		venue: {
			name: 'Prudential Center',
			link: '/api/v1/venues/null',
			city: 'Newark',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'NJD',
		teamName: 'Devils',
		locationName: 'New Jersey',
		firstYearOfPlay: '1982',
		division: {
			id: 18,
			name: 'Metropolitan',
			nameShort: 'Metro',
			link: '/api/v1/divisions/18',
			abbreviation: 'M',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 23,
			teamName: 'Devils',
			link: '/api/v1/franchises/23',
		},
		shortName: 'New Jersey',
		officialSiteUrl: 'http://www.newjerseydevils.com/',
		franchiseId: 23,
		active: true,
	},
	{
		id: 2,
		name: 'New York Islanders',
		link: '/api/v1/teams/2',
		venue: {
			name: 'UBS Arena',
			link: '/api/v1/venues/null',
			city: 'Elmont',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'NYI',
		teamName: 'Islanders',
		locationName: 'New York',
		firstYearOfPlay: '1972',
		division: {
			id: 18,
			name: 'Metropolitan',
			nameShort: 'Metro',
			link: '/api/v1/divisions/18',
			abbreviation: 'M',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 22,
			teamName: 'Islanders',
			link: '/api/v1/franchises/22',
		},
		shortName: 'NY Islanders',
		officialSiteUrl: 'http://www.newyorkislanders.com/',
		franchiseId: 22,
		active: true,
	},
	{
		id: 3,
		name: 'New York Rangers',
		link: '/api/v1/teams/3',
		venue: {
			id: 5054,
			name: 'Madison Square Garden',
			link: '/api/v1/venues/5054',
			city: 'New York',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'NYR',
		teamName: 'Rangers',
		locationName: 'New York',
		firstYearOfPlay: '1926',
		division: {
			id: 18,
			name: 'Metropolitan',
			nameShort: 'Metro',
			link: '/api/v1/divisions/18',
			abbreviation: 'M',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 10,
			teamName: 'Rangers',
			link: '/api/v1/franchises/10',
		},
		shortName: 'NY Rangers',
		officialSiteUrl: 'http://www.newyorkrangers.com/',
		franchiseId: 10,
		active: true,
	},
	{
		id: 4,
		name: 'Philadelphia Flyers',
		link: '/api/v1/teams/4',
		venue: {
			id: 5096,
			name: 'Wells Fargo Center',
			link: '/api/v1/venues/5096',
			city: 'Philadelphia',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'PHI',
		teamName: 'Flyers',
		locationName: 'Philadelphia',
		firstYearOfPlay: '1967',
		division: {
			id: 18,
			name: 'Metropolitan',
			nameShort: 'Metro',
			link: '/api/v1/divisions/18',
			abbreviation: 'M',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 16,
			teamName: 'Flyers',
			link: '/api/v1/franchises/16',
		},
		shortName: 'Philadelphia',
		officialSiteUrl: 'http://www.philadelphiaflyers.com/',
		franchiseId: 16,
		active: true,
	},
	{
		id: 5,
		name: 'Pittsburgh Penguins',
		link: '/api/v1/teams/5',
		venue: {
			id: 5034,
			name: 'PPG Paints Arena',
			link: '/api/v1/venues/5034',
			city: 'Pittsburgh',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'PIT',
		teamName: 'Penguins',
		locationName: 'Pittsburgh',
		firstYearOfPlay: '1967',
		division: {
			id: 18,
			name: 'Metropolitan',
			nameShort: 'Metro',
			link: '/api/v1/divisions/18',
			abbreviation: 'M',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 17,
			teamName: 'Penguins',
			link: '/api/v1/franchises/17',
		},
		shortName: 'Pittsburgh',
		officialSiteUrl: 'http://pittsburghpenguins.com/',
		franchiseId: 17,
		active: true,
	},
	{
		id: 6,
		name: 'Boston Bruins',
		link: '/api/v1/teams/6',
		venue: {
			id: 5085,
			name: 'TD Garden',
			link: '/api/v1/venues/5085',
			city: 'Boston',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'BOS',
		teamName: 'Bruins',
		locationName: 'Boston',
		firstYearOfPlay: '1924',
		division: {
			id: 17,
			name: 'Atlantic',
			nameShort: 'ATL',
			link: '/api/v1/divisions/17',
			abbreviation: 'A',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 6,
			teamName: 'Bruins',
			link: '/api/v1/franchises/6',
		},
		shortName: 'Boston',
		officialSiteUrl: 'http://www.bostonbruins.com/',
		franchiseId: 6,
		active: true,
	},
	{
		id: 7,
		name: 'Buffalo Sabres',
		link: '/api/v1/teams/7',
		venue: {
			id: 5039,
			name: 'KeyBank Center',
			link: '/api/v1/venues/5039',
			city: 'Buffalo',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'BUF',
		teamName: 'Sabres',
		locationName: 'Buffalo',
		firstYearOfPlay: '1970',
		division: {
			id: 17,
			name: 'Atlantic',
			nameShort: 'ATL',
			link: '/api/v1/divisions/17',
			abbreviation: 'A',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 19,
			teamName: 'Sabres',
			link: '/api/v1/franchises/19',
		},
		shortName: 'Buffalo',
		officialSiteUrl: 'http://www.sabres.com/',
		franchiseId: 19,
		active: true,
	},
	{
		id: 8,
		name: 'Montréal Canadiens',
		link: '/api/v1/teams/8',
		venue: {
			id: 5028,
			name: 'Bell Centre',
			link: '/api/v1/venues/5028',
			city: 'Montréal',
			timeZone: {
				id: 'America/Montreal',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'MTL',
		teamName: 'Canadiens',
		locationName: 'Montréal',
		firstYearOfPlay: '1909',
		division: {
			id: 17,
			name: 'Atlantic',
			nameShort: 'ATL',
			link: '/api/v1/divisions/17',
			abbreviation: 'A',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 1,
			teamName: 'Canadiens',
			link: '/api/v1/franchises/1',
		},
		shortName: 'Montréal',
		officialSiteUrl: 'http://www.canadiens.com/',
		franchiseId: 1,
		active: true,
	},
	{
		id: 9,
		name: 'Ottawa Senators',
		link: '/api/v1/teams/9',
		venue: {
			id: 5031,
			name: 'Canadian Tire Centre',
			link: '/api/v1/venues/5031',
			city: 'Ottawa',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'OTT',
		teamName: 'Senators',
		locationName: 'Ottawa',
		firstYearOfPlay: '1990',
		division: {
			id: 17,
			name: 'Atlantic',
			nameShort: 'ATL',
			link: '/api/v1/divisions/17',
			abbreviation: 'A',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 30,
			teamName: 'Senators',
			link: '/api/v1/franchises/30',
		},
		shortName: 'Ottawa',
		officialSiteUrl: 'http://www.ottawasenators.com/',
		franchiseId: 30,
		active: true,
	},
	{
		id: 10,
		name: 'Toronto Maple Leafs',
		link: '/api/v1/teams/10',
		venue: {
			name: 'Scotiabank Arena',
			link: '/api/v1/venues/null',
			city: 'Toronto',
			timeZone: {
				id: 'America/Toronto',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'TOR',
		teamName: 'Maple Leafs',
		locationName: 'Toronto',
		firstYearOfPlay: '1917',
		division: {
			id: 17,
			name: 'Atlantic',
			nameShort: 'ATL',
			link: '/api/v1/divisions/17',
			abbreviation: 'A',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 5,
			teamName: 'Maple Leafs',
			link: '/api/v1/franchises/5',
		},
		shortName: 'Toronto',
		officialSiteUrl: 'http://www.mapleleafs.com/',
		franchiseId: 5,
		active: true,
	},
	{
		id: 12,
		name: 'Carolina Hurricanes',
		link: '/api/v1/teams/12',
		venue: {
			id: 5066,
			name: 'PNC Arena',
			link: '/api/v1/venues/5066',
			city: 'Raleigh',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'CAR',
		teamName: 'Hurricanes',
		locationName: 'Carolina',
		firstYearOfPlay: '1979',
		division: {
			id: 18,
			name: 'Metropolitan',
			nameShort: 'Metro',
			link: '/api/v1/divisions/18',
			abbreviation: 'M',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 26,
			teamName: 'Hurricanes',
			link: '/api/v1/franchises/26',
		},
		shortName: 'Carolina',
		officialSiteUrl: 'http://www.carolinahurricanes.com/',
		franchiseId: 26,
		active: true,
	},
	{
		id: 13,
		name: 'Florida Panthers',
		link: '/api/v1/teams/13',
		venue: {
			id: 5027,
			name: 'FLA Live Arena',
			link: '/api/v1/venues/5027',
			city: 'Sunrise',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'FLA',
		teamName: 'Panthers',
		locationName: 'Florida',
		firstYearOfPlay: '1993',
		division: {
			id: 17,
			name: 'Atlantic',
			nameShort: 'ATL',
			link: '/api/v1/divisions/17',
			abbreviation: 'A',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 33,
			teamName: 'Panthers',
			link: '/api/v1/franchises/33',
		},
		shortName: 'Florida',
		officialSiteUrl: 'http://www.floridapanthers.com/',
		franchiseId: 33,
		active: true,
	},
	{
		id: 14,
		name: 'Tampa Bay Lightning',
		link: '/api/v1/teams/14',
		venue: {
			name: 'AMALIE Arena',
			link: '/api/v1/venues/null',
			city: 'Tampa',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'TBL',
		teamName: 'Lightning',
		locationName: 'Tampa Bay',
		firstYearOfPlay: '1991',
		division: {
			id: 17,
			name: 'Atlantic',
			nameShort: 'ATL',
			link: '/api/v1/divisions/17',
			abbreviation: 'A',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 31,
			teamName: 'Lightning',
			link: '/api/v1/franchises/31',
		},
		shortName: 'Tampa Bay',
		officialSiteUrl: 'http://www.tampabaylightning.com/',
		franchiseId: 31,
		active: true,
	},
	{
		id: 15,
		name: 'Washington Capitals',
		link: '/api/v1/teams/15',
		venue: {
			id: 5094,
			name: 'Capital One Arena',
			link: '/api/v1/venues/5094',
			city: 'Washington',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'WSH',
		teamName: 'Capitals',
		locationName: 'Washington',
		firstYearOfPlay: '1974',
		division: {
			id: 18,
			name: 'Metropolitan',
			nameShort: 'Metro',
			link: '/api/v1/divisions/18',
			abbreviation: 'M',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 24,
			teamName: 'Capitals',
			link: '/api/v1/franchises/24',
		},
		shortName: 'Washington',
		officialSiteUrl: 'http://www.washingtoncapitals.com/',
		franchiseId: 24,
		active: true,
	},
	{
		id: 16,
		name: 'Chicago Blackhawks',
		link: '/api/v1/teams/16',
		venue: {
			id: 5092,
			name: 'United Center',
			link: '/api/v1/venues/5092',
			city: 'Chicago',
			timeZone: {
				id: 'America/Chicago',
				offset: -6,
				tz: 'CST',
			},
		},
		abbreviation: 'CHI',
		teamName: 'Blackhawks',
		locationName: 'Chicago',
		firstYearOfPlay: '1926',
		division: {
			id: 16,
			name: 'Central',
			nameShort: 'CEN',
			link: '/api/v1/divisions/16',
			abbreviation: 'C',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 11,
			teamName: 'Blackhawks',
			link: '/api/v1/franchises/11',
		},
		shortName: 'Chicago',
		officialSiteUrl: 'http://www.chicagoblackhawks.com/',
		franchiseId: 11,
		active: true,
	},
	{
		id: 17,
		name: 'Detroit Red Wings',
		link: '/api/v1/teams/17',
		venue: {
			id: 5145,
			name: 'Little Caesars Arena',
			link: '/api/v1/venues/5145',
			city: 'Detroit',
			timeZone: {
				id: 'America/Detroit',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'DET',
		teamName: 'Red Wings',
		locationName: 'Detroit',
		firstYearOfPlay: '1926',
		division: {
			id: 17,
			name: 'Atlantic',
			nameShort: 'ATL',
			link: '/api/v1/divisions/17',
			abbreviation: 'A',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 12,
			teamName: 'Red Wings',
			link: '/api/v1/franchises/12',
		},
		shortName: 'Detroit',
		officialSiteUrl: 'http://www.detroitredwings.com/',
		franchiseId: 12,
		active: true,
	},
	{
		id: 18,
		name: 'Nashville Predators',
		link: '/api/v1/teams/18',
		venue: {
			id: 5030,
			name: 'Bridgestone Arena',
			link: '/api/v1/venues/5030',
			city: 'Nashville',
			timeZone: {
				id: 'America/Chicago',
				offset: -6,
				tz: 'CST',
			},
		},
		abbreviation: 'NSH',
		teamName: 'Predators',
		locationName: 'Nashville',
		firstYearOfPlay: '1997',
		division: {
			id: 16,
			name: 'Central',
			nameShort: 'CEN',
			link: '/api/v1/divisions/16',
			abbreviation: 'C',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 34,
			teamName: 'Predators',
			link: '/api/v1/franchises/34',
		},
		shortName: 'Nashville',
		officialSiteUrl: 'http://www.nashvillepredators.com/',
		franchiseId: 34,
		active: true,
	},
	{
		id: 19,
		name: 'St. Louis Blues',
		link: '/api/v1/teams/19',
		venue: {
			id: 5076,
			name: 'Enterprise Center',
			link: '/api/v1/venues/5076',
			city: 'St. Louis',
			timeZone: {
				id: 'America/Chicago',
				offset: -6,
				tz: 'CST',
			},
		},
		abbreviation: 'STL',
		teamName: 'Blues',
		locationName: 'St. Louis',
		firstYearOfPlay: '1967',
		division: {
			id: 16,
			name: 'Central',
			nameShort: 'CEN',
			link: '/api/v1/divisions/16',
			abbreviation: 'C',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 18,
			teamName: 'Blues',
			link: '/api/v1/franchises/18',
		},
		shortName: 'St Louis',
		officialSiteUrl: 'http://www.stlouisblues.com/',
		franchiseId: 18,
		active: true,
	},
	{
		id: 20,
		name: 'Calgary Flames',
		link: '/api/v1/teams/20',
		venue: {
			id: 5075,
			name: 'Scotiabank Saddledome',
			link: '/api/v1/venues/5075',
			city: 'Calgary',
			timeZone: {
				id: 'America/Denver',
				offset: -7,
				tz: 'MST',
			},
		},
		abbreviation: 'CGY',
		teamName: 'Flames',
		locationName: 'Calgary',
		firstYearOfPlay: '1980',
		division: {
			id: 15,
			name: 'Pacific',
			nameShort: 'PAC',
			link: '/api/v1/divisions/15',
			abbreviation: 'P',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 21,
			teamName: 'Flames',
			link: '/api/v1/franchises/21',
		},
		shortName: 'Calgary',
		officialSiteUrl: 'http://www.calgaryflames.com/',
		franchiseId: 21,
		active: true,
	},
	{
		id: 21,
		name: 'Colorado Avalanche',
		link: '/api/v1/teams/21',
		venue: {
			id: 5064,
			name: 'Ball Arena',
			link: '/api/v1/venues/5064',
			city: 'Denver',
			timeZone: {
				id: 'America/Denver',
				offset: -7,
				tz: 'MST',
			},
		},
		abbreviation: 'COL',
		teamName: 'Avalanche',
		locationName: 'Colorado',
		firstYearOfPlay: '1979',
		division: {
			id: 16,
			name: 'Central',
			nameShort: 'CEN',
			link: '/api/v1/divisions/16',
			abbreviation: 'C',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 27,
			teamName: 'Avalanche',
			link: '/api/v1/franchises/27',
		},
		shortName: 'Colorado',
		officialSiteUrl: 'http://www.coloradoavalanche.com/',
		franchiseId: 27,
		active: true,
	},
	{
		id: 22,
		name: 'Edmonton Oilers',
		link: '/api/v1/teams/22',
		venue: {
			id: 5100,
			name: 'Rogers Place',
			link: '/api/v1/venues/5100',
			city: 'Edmonton',
			timeZone: {
				id: 'America/Edmonton',
				offset: -7,
				tz: 'MST',
			},
		},
		abbreviation: 'EDM',
		teamName: 'Oilers',
		locationName: 'Edmonton',
		firstYearOfPlay: '1979',
		division: {
			id: 15,
			name: 'Pacific',
			nameShort: 'PAC',
			link: '/api/v1/divisions/15',
			abbreviation: 'P',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 25,
			teamName: 'Oilers',
			link: '/api/v1/franchises/25',
		},
		shortName: 'Edmonton',
		officialSiteUrl: 'http://www.edmontonoilers.com/',
		franchiseId: 25,
		active: true,
	},
	{
		id: 23,
		name: 'Vancouver Canucks',
		link: '/api/v1/teams/23',
		venue: {
			id: 5073,
			name: 'Rogers Arena',
			link: '/api/v1/venues/5073',
			city: 'Vancouver',
			timeZone: {
				id: 'America/Vancouver',
				offset: -8,
				tz: 'PST',
			},
		},
		abbreviation: 'VAN',
		teamName: 'Canucks',
		locationName: 'Vancouver',
		firstYearOfPlay: '1970',
		division: {
			id: 15,
			name: 'Pacific',
			nameShort: 'PAC',
			link: '/api/v1/divisions/15',
			abbreviation: 'P',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 20,
			teamName: 'Canucks',
			link: '/api/v1/franchises/20',
		},
		shortName: 'Vancouver',
		officialSiteUrl: 'http://www.canucks.com/',
		franchiseId: 20,
		active: true,
	},
	{
		id: 24,
		name: 'Anaheim Ducks',
		link: '/api/v1/teams/24',
		venue: {
			id: 5046,
			name: 'Honda Center',
			link: '/api/v1/venues/5046',
			city: 'Anaheim',
			timeZone: {
				id: 'America/Los_Angeles',
				offset: -8,
				tz: 'PST',
			},
		},
		abbreviation: 'ANA',
		teamName: 'Ducks',
		locationName: 'Anaheim',
		firstYearOfPlay: '1993',
		division: {
			id: 15,
			name: 'Pacific',
			nameShort: 'PAC',
			link: '/api/v1/divisions/15',
			abbreviation: 'P',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 32,
			teamName: 'Ducks',
			link: '/api/v1/franchises/32',
		},
		shortName: 'Anaheim',
		officialSiteUrl: 'http://www.anaheimducks.com/',
		franchiseId: 32,
		active: true,
	},
	{
		id: 25,
		name: 'Dallas Stars',
		link: '/api/v1/teams/25',
		venue: {
			id: 5019,
			name: 'American Airlines Center',
			link: '/api/v1/venues/5019',
			city: 'Dallas',
			timeZone: {
				id: 'America/Chicago',
				offset: -6,
				tz: 'CST',
			},
		},
		abbreviation: 'DAL',
		teamName: 'Stars',
		locationName: 'Dallas',
		firstYearOfPlay: '1967',
		division: {
			id: 16,
			name: 'Central',
			nameShort: 'CEN',
			link: '/api/v1/divisions/16',
			abbreviation: 'C',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 15,
			teamName: 'Stars',
			link: '/api/v1/franchises/15',
		},
		shortName: 'Dallas',
		officialSiteUrl: 'http://www.dallasstars.com/',
		franchiseId: 15,
		active: true,
	},
	{
		id: 26,
		name: 'Los Angeles Kings',
		link: '/api/v1/teams/26',
		venue: {
			name: 'Crypto.com Arena',
			link: '/api/v1/venues/null',
			city: 'Los Angeles',
			timeZone: {
				id: 'America/Los_Angeles',
				offset: -8,
				tz: 'PST',
			},
		},
		abbreviation: 'LAK',
		teamName: 'Kings',
		locationName: 'Los Angeles',
		firstYearOfPlay: '1967',
		division: {
			id: 15,
			name: 'Pacific',
			nameShort: 'PAC',
			link: '/api/v1/divisions/15',
			abbreviation: 'P',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 14,
			teamName: 'Kings',
			link: '/api/v1/franchises/14',
		},
		shortName: 'Los Angeles',
		officialSiteUrl: 'http://www.lakings.com/',
		franchiseId: 14,
		active: true,
	},
	{
		id: 28,
		name: 'San Jose Sharks',
		link: '/api/v1/teams/28',
		venue: {
			name: 'SAP Center at San Jose',
			link: '/api/v1/venues/null',
			city: 'San Jose',
			timeZone: {
				id: 'America/Los_Angeles',
				offset: -8,
				tz: 'PST',
			},
		},
		abbreviation: 'SJS',
		teamName: 'Sharks',
		locationName: 'San Jose',
		firstYearOfPlay: '1990',
		division: {
			id: 15,
			name: 'Pacific',
			nameShort: 'PAC',
			link: '/api/v1/divisions/15',
			abbreviation: 'P',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 29,
			teamName: 'Sharks',
			link: '/api/v1/franchises/29',
		},
		shortName: 'San Jose',
		officialSiteUrl: 'http://www.sjsharks.com/',
		franchiseId: 29,
		active: true,
	},
	{
		id: 29,
		name: 'Columbus Blue Jackets',
		link: '/api/v1/teams/29',
		venue: {
			id: 5059,
			name: 'Nationwide Arena',
			link: '/api/v1/venues/5059',
			city: 'Columbus',
			timeZone: {
				id: 'America/New_York',
				offset: -5,
				tz: 'EST',
			},
		},
		abbreviation: 'CBJ',
		teamName: 'Blue Jackets',
		locationName: 'Columbus',
		firstYearOfPlay: '1997',
		division: {
			id: 18,
			name: 'Metropolitan',
			nameShort: 'Metro',
			link: '/api/v1/divisions/18',
			abbreviation: 'M',
		},
		conference: {
			id: 6,
			name: 'Eastern',
			link: '/api/v1/conferences/6',
		},
		franchise: {
			franchiseId: 36,
			teamName: 'Blue Jackets',
			link: '/api/v1/franchises/36',
		},
		shortName: 'Columbus',
		officialSiteUrl: 'http://www.bluejackets.com/',
		franchiseId: 36,
		active: true,
	},
	{
		id: 30,
		name: 'Minnesota Wild',
		link: '/api/v1/teams/30',
		venue: {
			id: 5098,
			name: 'Xcel Energy Center',
			link: '/api/v1/venues/5098',
			city: 'St. Paul',
			timeZone: {
				id: 'America/Chicago',
				offset: -6,
				tz: 'CST',
			},
		},
		abbreviation: 'MIN',
		teamName: 'Wild',
		locationName: 'Minnesota',
		firstYearOfPlay: '1997',
		division: {
			id: 16,
			name: 'Central',
			nameShort: 'CEN',
			link: '/api/v1/divisions/16',
			abbreviation: 'C',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 37,
			teamName: 'Wild',
			link: '/api/v1/franchises/37',
		},
		shortName: 'Minnesota',
		officialSiteUrl: 'http://www.wild.com/',
		franchiseId: 37,
		active: true,
	},
	{
		id: 52,
		name: 'Winnipeg Jets',
		link: '/api/v1/teams/52',
		venue: {
			id: 5058,
			name: 'Canada Life Centre',
			link: '/api/v1/venues/5058',
			city: 'Winnipeg',
			timeZone: {
				id: 'America/Winnipeg',
				offset: -6,
				tz: 'CST',
			},
		},
		abbreviation: 'WPG',
		teamName: 'Jets',
		locationName: 'Winnipeg',
		firstYearOfPlay: '2011',
		division: {
			id: 16,
			name: 'Central',
			nameShort: 'CEN',
			link: '/api/v1/divisions/16',
			abbreviation: 'C',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 35,
			teamName: 'Jets',
			link: '/api/v1/franchises/35',
		},
		shortName: 'Winnipeg',
		officialSiteUrl: 'http://winnipegjets.com/',
		franchiseId: 35,
		active: true,
	},
	{
		id: 53,
		name: 'Arizona Coyotes',
		link: '/api/v1/teams/53',
		venue: {
			name: 'Mullett Arena',
			link: '/api/v1/venues/null',
			city: 'Tempe',
			timeZone: {
				id: 'America/Phoenix',
				offset: -7,
				tz: 'MST',
			},
		},
		abbreviation: 'ARI',
		teamName: 'Coyotes',
		locationName: 'Arizona',
		firstYearOfPlay: '1979',
		division: {
			id: 16,
			name: 'Central',
			nameShort: 'CEN',
			link: '/api/v1/divisions/16',
			abbreviation: 'C',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 28,
			teamName: 'Coyotes',
			link: '/api/v1/franchises/28',
		},
		shortName: 'Arizona',
		officialSiteUrl: 'http://www.arizonacoyotes.com/',
		franchiseId: 28,
		active: true,
	},
	{
		id: 54,
		name: 'Vegas Golden Knights',
		link: '/api/v1/teams/54',
		venue: {
			id: 5178,
			name: 'T-Mobile Arena',
			link: '/api/v1/venues/5178',
			city: 'Las Vegas',
			timeZone: {
				id: 'America/Los_Angeles',
				offset: -8,
				tz: 'PST',
			},
		},
		abbreviation: 'VGK',
		teamName: 'Golden Knights',
		locationName: 'Vegas',
		firstYearOfPlay: '2016',
		division: {
			id: 15,
			name: 'Pacific',
			nameShort: 'PAC',
			link: '/api/v1/divisions/15',
			abbreviation: 'P',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 38,
			teamName: 'Golden Knights',
			link: '/api/v1/franchises/38',
		},
		shortName: 'Vegas',
		officialSiteUrl: 'http://www.vegasgoldenknights.com/',
		franchiseId: 38,
		active: true,
	},
	{
		id: 55,
		name: 'Seattle Kraken',
		link: '/api/v1/teams/55',
		venue: {
			name: 'Climate Pledge Arena',
			link: '/api/v1/venues/null',
			city: 'Seattle',
			timeZone: {
				id: 'America/Los_Angeles',
				offset: -8,
				tz: 'PST',
			},
		},
		abbreviation: 'SEA',
		teamName: 'Kraken',
		locationName: 'Seattle',
		firstYearOfPlay: '2021',
		division: {
			id: 15,
			name: 'Pacific',
			nameShort: 'PAC',
			link: '/api/v1/divisions/15',
			abbreviation: 'P',
		},
		conference: {
			id: 5,
			name: 'Western',
			link: '/api/v1/conferences/5',
		},
		franchise: {
			franchiseId: 39,
			teamName: 'Kraken',
			link: '/api/v1/franchises/39',
		},
		shortName: 'Seattle',
		officialSiteUrl: 'https://www.nhl.com/seattle',
		franchiseId: 39,
		active: true,
	},
]
